import React from 'react';
import { ThemeProvider } from 'styled-components';
import Navbar from './Navbar';
import Footer from './Footer';
import { theme } from '../styles/theme';
import { GlobalStyle } from '../styles/GlobalStyle';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Navbar />
    {children}
    <Footer />
  </ThemeProvider>
);

export default Layout;
