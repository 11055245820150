import styled from 'styled-components';

export const NavbarStyle = styled.nav`
  position: relative;
  height: 70px;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-bottom: 2px solid #292929;
  z-index: 3;

  ul {
    display: flex;
    justify-content: space-around;
  }

  ul li a {
    margin-left: 2rem;
    font-size: 1rem;
    color: #ffffff;
    position: relative;
  }

  ul li a::after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: -4px;
    left: 0;
    right: 0;
    width: 100%;
    background: ${({ theme }) => theme.colors.green};

    visibility: hidden;
    opacity: 0;
    -o-transition: visibility 0s, opacity 0.1s ease-in-out;
    -ms-transition: visibility 0s, opacity 0.1s ease-in-out;
    -moz-transition: visibility 0s, opacity 0.1s ease-in-out;
    -webkit-transition: visibility 0s, opacity 0.1s ease-in-out;
    transition: visibility 0s, opacity 0.1s ease-in-out;
  }

  ul li a:hover::after {
    visibility: visible;
    opacity: 1;
  }

  .active {
    transform: translateX(0%);
    transition: transform 0.3s ease-out;
  }

  .burgerContainer {
    position: relative;
    display: none;
  }

  /* Toggler */
  input {
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
  }

  /* Toggler animate */
  input:checked + .burger > div {
    transform: rotate(135deg);
  }

  /* Turns lines into X */
  input:checked + .burger > div:before,
  input:checked + .burger > div:after {
    top: 0;
    transform: rotate(90deg);
  }

  /* Rotate on hover */
  input:checked:hover + .burger > div {
    transform: rotate(225deg);
  }

  .burger {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .burger > div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    transition: all 0.4s ease-out;
  }

  /* Top and bottom line */
  .burger > div:before,
  .burger > div:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    z-index: 1;
    top: -10px;
    background-color: inherit;
  }

  /* // Moves line down */
  .burger > div:after {
    top: 10px;
  }

  @media screen and (max-width: 768px) {
    ul {
      position: fixed;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;
      z-index: -1;
      top: 0;
      right: 0px;
      align-items: center;
      background-color: ${({ theme }) => theme.colors.primary};
      transform: translateX(100%);
      transition: transform 0.3s ease-in;
    }

    ul li {
      margin-top: 4rem;
    }

    ul li a {
      margin-left: 0;
      font-size: 1.25rem;
    }

    .burgerContainer {
      display: block;
    }
  }
`;
