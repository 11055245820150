import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from 'react-icons/ai';
import { FaInstagramSquare } from 'react-icons/fa';
import { getYear } from '../helpers/date';

import { SectionComponent } from '../styles/StyledComponents';

export const ContainerIndex = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  & > div {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (max-width: 768px) {
    & > div {
      flex-direction: column;
    }
  }

  footer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #8c8c8c;
    font-weight: 300;
    font-size: 0.8rem;
  }
`;

export const Card = styled.div`
  padding: 2rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-bottom: 2rem;
    font-weight: 500;
  }

  a,
  span {
    color: white;
    margin: 0.15rem 0;
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2.2rem;
  a {
    padding: 0 0.5rem;
  }
`;

export const Links = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: max-content;
  text-align: left;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Footer = () => {
  return (
    <SectionComponent>
      <ContainerIndex dark pd="1rem 0">
        <div>
          <Card pd="2rem">
            <h1>Links</h1>
            <Links>
              <Link to="/presskit">Press Kit</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/faq">FAQ</Link>
              <Link to="/terms">Terms</Link>
              <Link to="/intellectual-property">Intellectual Property</Link>
            </Links>
          </Card>
          <Card pd="2rem">
            <h1>Contact Us</h1>
            <span>info@teampoker.com</span>
            <span>Phone: (970) 290-3936</span>
          </Card>
          <Card pd="2rem">
            <h1>Follow Us</h1>
            <Social>
              <a
                href="https://www.facebook.com/PlayTeamPoker"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/18173656"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillLinkedin />
              </a>
              <a
                href="https://twitter.com/teampoker"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillTwitterSquare />
              </a>
              <a
                href="https://www.instagram.com/teampoker/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagramSquare />
              </a>
            </Social>
          </Card>
        </div>
        <footer>
          <span>TeamPoker®</span>
          <span>Patent 7819735 Oct 2010</span>
          <span>© 1999-{getYear()} All Rights Reserved.</span>
        </footer>
      </ContainerIndex>
    </SectionComponent>
  );
};

export default Footer;
