import React from 'react';
import { Link } from 'gatsby';
import Logo from '../utils/Logo';
import { NavbarStyle } from '../styles/Navbar';

const Navbar = () => {
  const [isNavbarOpen, changeNavbar] = React.useState(false);

  const inputEl = React.useRef(null);

  return (
    <NavbarStyle>
      <Logo />
      <ul ref={inputEl} className={isNavbarOpen ? 'active' : undefined}>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/partners">Partners</Link>
        </li>
        <li>
          <Link to="/gallery">Gallery</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <div
        role="button"
        tabIndex={0}
        className="burgerContainer"
        onClick={() => changeNavbar(!isNavbarOpen)}
        onKeyDown={() => changeNavbar(!isNavbarOpen)}
      >
        <input type="checkbox" />
        <div className="burger">
          <div />
        </div>
      </div>
    </NavbarStyle>
  );
};

export default Navbar;
