import styled from 'styled-components';

export const LogoStyle = styled.div`
  display: block;
  z-index: 1000;
  width: 240px;

  @media screen and (max-width: 768px) {
    width: 220px;
  }
`;
