import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { LogoStyle } from '../styles/Logo';

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Link to="/">
      <LogoStyle>
        <Img fluid={data.file.childImageSharp.fluid} />
      </LogoStyle>
    </Link>
  );
};

export default Logo;
