import styled from 'styled-components';

export const Container = styled.div`
  margin: 3rem auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const H1Component = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const ButtonComponent = styled.button`
  font-size: 1.5rem;
  font-weight: 300;
  min-width: 280px;
  max-width: 360px;
  background-color: ${({ theme }) => theme.colors.green};
  padding: 0.4rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 25px;
  color: white;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  &:hover {
    box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.25);
    background-color: #02c29d;
  }
`;

export const HeadlineComponent = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.1rem;
  color: ${({ theme, color }) => color || theme.colors.primary};

  @media screen and (max-width: 768px) {
  font-size: 1.8rem;
    } 
`;

export const ParagraphComponent = styled.p`
  font-size: ${({ size }) => size || '1rem'};
  font-weight: 400;
  text-align: ${({ align }) => align && align};
  padding-bottom: ${({ pb }) => pb || 0};
`;

export const SectionComponent = styled.section`
  padding-top: 4rem;
  text-align: center;
  margin-bottom: ${({ mg }) => mg || 0};
`;

export const IconComponent = styled.div`
  font-size: 4rem;
  margin: 2rem;
  display: flex;
  justify-content: center;
`;
